@charset "UTF-8";
body {
  font-size: 1.6rem;
  font-family: "Noto Sans JP", "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
  font-style: normal;
  line-height: 1.6;
  font-weight: 400;
  color: #47220d;
}

/*==================================================
ふわっ
===================================*/
/* その場で */
.fadeIn {
  animation-name: fadeInAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeInAnime {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 下から */
.fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 上から */
.fadeDown {
  animation-name: fadeDownAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeDownAnime {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 左から */
.fadeLeft {
  animation-name: fadeLeftAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeLeftAnime {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* 右から */
.fadeRight {
  animation-name: fadeRightAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeRightAnime {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.fadeInTrigger,
.fadeUpTrigger,
.fadeDownTrigger,
.fadeLeftTrigger,
.fadeRightTrigger {
  opacity: 0;
}

/*==================================================
パタッ
===================================*/
/* 下へ */
.flipDown {
  animation-name: flipDownAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes flipDownAnime {
  from {
    transform: perspective(2500px) rotateX(100deg);
    opacity: 0;
  }
  to {
    transform: perspective(2500px) rotateX(0);
    opacity: 1;
  }
}

/* 左へ */
.flipLeft {
  animation-name: flipLeftAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  perspective-origin: left center;
  opacity: 0;
}

@keyframes flipLeftAnime {
  from {
    transform: perspective(600px) translate3d(0, 0, 0) rotateY(30deg);
    opacity: 0;
  }
  to {
    transform: perspective(600px) translate3d(0, 0, 0) rotateY(0deg);
    opacity: 1;
  }
}

/* 左上へ */
.flipLeftTop {
  animation-name: flipLeftTopAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes flipLeftTopAnime {
  from {
    transform: translate(-20px, 80px) rotate(-15deg);
    opacity: 0;
  }
  to {
    transform: translate(0, 0) rotate(0deg);
    opacity: 1;
  }
}

/* 右へ */
.flipRight {
  animation-name: flipRightAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  perspective-origin: right center;
  opacity: 0;
}

@keyframes flipRightAnime {
  from {
    transform: perspective(600px) translate3d(0, 0, 0) rotateY(-30deg);
    opacity: 0;
  }
  to {
    transform: perspective(600px) translate3d(0, 0, 0) rotateY(0deg);
    opacity: 1;
  }
}

/* 右上へ */
.flipRightTop {
  animation-name: flipRightTopAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes flipRightTopAnime {
  from {
    transform: translate(-20px, 80px) rotate(25deg);
    opacity: 0;
  }
  to {
    transform: translate(0, 1) rotate(0deg);
    opacity: 1;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.flipDownTrigger,
.flipLeftTrigger,
.flipLeftTopTrigger,
.flipRightTrigger,
.flipRightTopTrigger {
  opacity: 0;
}

/*==================================================
くるっ
===================================*/
/* X 軸（縦へ） */
.rotateX {
  animation-name: rotateXAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes rotateXAnime {
  from {
    transform: rotateX(0);
    opacity: 0;
  }
  to {
    transform: rotateX(-360deg);
    opacity: 1;
  }
}

/*　Y軸（横へ） */
.rotateY {
  animation-name: rotateYAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes rotateYAnime {
  from {
    transform: rotateY(0);
    opacity: 0;
  }
  to {
    transform: rotateY(-360deg);
    opacity: 1;
  }
}

/* Z 軸（左へ） */
.rotateLeftZ {
  animation-name: rotateLeftZAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes rotateLeftZAnime {
  from {
    transform: rotateZ(0);
    opacity: 0;
  }
  to {
    transform: rotateZ(-360deg);
    opacity: 1;
  }
}

/*　Z 軸（右へ） */
.rotateRightZ {
  animation-name: rotateRightZAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes rotateRightZAnime {
  from {
    transform: rotateZ(0);
    opacity: 0;
  }
  to {
    transform: rotateZ(360deg);
    opacity: 1;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.rotateXTrigger,
.rotateYTrigger,
.rotateLeftZTrigger,
.rotateRightZTrigger {
  opacity: 0;
}

/*==================================================
ボンッ、ヒュッ
===================================*/
/* 拡大 */
.zoomIn {
  animation-name: zoomInAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes zoomInAnime {
  from {
    transform: scale(0.6);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* 拡大 */
.zoomInLeft {
  animation-name: zoomInLeftAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes zoomInLeftAnime {
  from {
    transform: scale(0.6) translateX(-50%);
    opacity: 0;
  }
  to {
    transform: scale(1) translateX(0px);
    opacity: 1;
  }
}

/* 拡大 */
.zoomInRight {
  animation-name: zoomInRightAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes zoomInRightAnime {
  from {
    transform: scale(0.6) translateX(50%);
    opacity: 0;
  }
  to {
    transform: scale(1) translateX(0%);
    opacity: 1;
  }
}

/* 縮小 */
.zoomOut {
  animation-name: zoomOutAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes zoomOutAnime {
  from {
    transform: scale(1.2);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.zoomInTrigger,
.zoomOutTrigger,
.zoomInLeftTrigger,
.zoomInRightTrigger {
  opacity: 0;
}

/*==================================================
じわっ
===================================*/
/* ぼかしから出現 */
.blur {
  animation-name: blurAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes blurAnime {
  from {
    filter: blur(10px);
    transform: scale(1.02);
    opacity: 0;
  }
  to {
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.blurTrigger {
  opacity: 0;
}

/*==================================================
にゅーん
===================================*/
/* 滑らかに変形して出現 */
.smooth {
  animation-name: smoothAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  transform-origin: left;
  opacity: 0;
}

@keyframes smoothAnime {
  from {
    transform: translate3d(0, 100%, 0) skewY(12deg);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0) skewY(0);
    opacity: 1;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.smoothTrigger {
  opacity: 0;
}

/*==================================================
スーッ（枠線が伸びて出現）
===================================*/
/*枠線が伸びて出現*/
.lineTrigger {
  position: relative;
  /* 枠線が書かれる基点*/
  opacity: 0;
}

.lineTrigger.lineanime {
  animation-name: lineAnimeBase;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes lineAnimeBase {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*上下線*/
.lineTrigger::before,
.lineTrigger::after {
  position: absolute;
  content: "";
  width: 0;
  height: 1px;
  background: #333;
  /* 枠線の色*/
}

/*左右線*/
.line2::before,
.line2::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 0;
  background: #333;
  /* 枠線の色*/
}

/*上線*/
.lineTrigger::before {
  top: 0;
  left: 0;
}

.lineTrigger.lineanime::before {
  animation: lineAnime 0.5s linear 0s forwards;
  /*表示されて0秒後に上線が0.5秒かけて表示*/
}

/*右線*/
.line2::before {
  top: 0;
  right: 0;
}

.lineTrigger.lineanime .line2::before {
  animation: lineAnime2 0.5s linear 0.5s forwards;
  /*表示されて0.5秒後に右線が0.5秒かけて表示*/
}

/*下線*/
.lineTrigger::after {
  bottom: 0;
  right: 0;
}

.lineTrigger.lineanime::after {
  animation: lineAnime 0.5s linear 1s forwards;
  /*表示されて1秒後に下線が0.5秒かけて表示*/
}

/*左線*/
.line2::after {
  bottom: 0;
  left: 0;
}

.lineTrigger.lineanime .line2::after {
  animation: lineAnime2 0.5s linear 1.5s forwards;
  /*表示されて1.5秒後に左線が0.5秒かけて表示*/
}

@keyframes lineAnime {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes lineAnime2 {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

/*枠線内側の要素*/
.lineTrigger.lineanime .lineinappear {
  animation: lineInnerAnime 0.5s linear 1.5s forwards;
  /*1.5秒後に中央のエリアが0.5秒かけて表示*/
  opacity: 0;
  /*初期値を透過0にする*/
}

@keyframes lineInnerAnime {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*==================================================
シャッ（背景色が伸びて出現）
===================================*/
/*背景色が伸びて出現（共通）*/
.bgextend {
  animation-name: bgextendAnimeBase;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  position: relative;
  overflow: hidden;
  /*　はみ出た色要素を隠す　*/
  opacity: 0;
}

@keyframes bgextendAnimeBase {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*中の要素*/
.bgappear {
  animation-name: bgextendAnimeSecond;
  animation-duration: 1s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes bgextendAnimeSecond {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*左から*/
.bgLRextend::before {
  animation-name: bgLRextendAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #666;
  /*伸びる背景色の設定*/
}

@keyframes bgLRextendAnime {
  0% {
    transform-origin: left;
    transform: scaleX(0);
  }
  50% {
    transform-origin: left;
    transform: scaleX(1);
  }
  50.001% {
    transform-origin: right;
  }
  100% {
    transform-origin: right;
    transform: scaleX(0);
  }
}

@keyframes bgLRextendAnime {
  0% {
    transform-origin: left;
    transform: scaleX(0);
  }
  50% {
    transform-origin: left;
    transform: scaleX(1);
  }
  50.001% {
    transform-origin: right;
  }
  100% {
    transform-origin: right;
    transform: scaleX(0);
  }
}

/*右から*/
.bgRLextend::before {
  animation-name: bgRLextendAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #666;
  /*伸びる背景色の設定*/
}

@keyframes bgRLextendAnime {
  0% {
    transform-origin: right;
    transform: scaleX(0);
  }
  50% {
    transform-origin: right;
    transform: scaleX(1);
  }
  50.001% {
    transform-origin: left;
  }
  100% {
    transform-origin: left;
    transform: scaleX(0);
  }
}

/*下から*/
.bgDUextend::before {
  animation-name: bgDUextendAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #666;
  /*伸びる背景色の設定*/
}

@keyframes bgDUextendAnime {
  0% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
  50% {
    transform-origin: bottom;
    transform: scaleY(1);
  }
  50.001% {
    transform-origin: top;
  }
  100% {
    transform-origin: top;
    transform: scaleY(0);
  }
}

/*上から*/
.bgUDextend::before {
  animation-name: bgUDextendAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #666;
  /*伸びる背景色の設定*/
}

@keyframes bgUDextendAnime {
  0% {
    transform-origin: top;
    transform: scaleY(0);
  }
  50% {
    transform-origin: top;
    transform: scaleY(1);
  }
  50.001% {
    transform-origin: bottom;
  }
  100% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
}

.LR {
  white-space: nowrap;
  overflow: hidden;
  animation: LRAnime 1.5s forwards linear;
  width: 0;
  margin: 0;
}

@keyframes LRAnime {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.TB {
  white-space: nowrap;
  overflow: hidden;
  animation: TBAnime 1.5s forwards linear;
  height: 0;
  margin: 0;
  display: inline-block;
}

@keyframes TBAnime {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.bgappearTrigger,
.bgUDextendTrigger,
.bgDUextendTrigger,
.bgRLextendTrigger,
.bgLRextendTrigger {
  opacity: 0;
}

.flowing {
  width: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  animation: flowing-anim 1s forwards linear;
  display: block;
}

@keyframes flowing-anim {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

/* .flowingTrigger {
  opacity: 0;
} */
/*========= レイアウトのためのCSS ===============*/
.wrapper {
  overflow: hidden;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.box {
  width: 220px;
  padding: 20px;
  margin: 0 20px 20px 20px;
  background: #666;
  color: #fff;
  box-sizing: border-box;
}

.bgextend,
.lineTrigger {
  width: 220px;
  padding: 20px;
  margin: 0 20px 20px 20px;
  box-sizing: border-box;
}

/*==================================================
アニメーション設定
===================================*/
/* アニメーションの回数を決めるCSS*/
.count2 {
  animation-iteration-count: 2;
  /*この数字を必要回数分に変更*/
}

.countinfinite {
  animation-iteration-count: infinite;
  /*無限ループ*/
}

/* アニメーションスタートの遅延時間を決めるCSS*/
.delay-time01 {
  animation-delay: 0.1s;
}

.delay-time02 {
  animation-delay: 0.2s;
}

.delay-time03 {
  animation-delay: 0.3s;
}

.delay-time04 {
  animation-delay: 0.4s;
}

.delay-time04._sp {
  animation-delay: 0.4s;
}

@media (max-width: 767px) {
  .delay-time01 {
    animation-delay: 0s;
  }
  .delay-time02 {
    animation-delay: 0s;
  }
  .delay-time04 {
    animation-delay: 0s;
  }
}

.delay-time05 {
  animation-delay: 0.5s;
}

.delay-time1 {
  animation-delay: 1s;
}

.delay-time15 {
  animation-delay: 1.5s;
}

.delay-time2 {
  animation-delay: 2s;
}

.delay-time25 {
  animation-delay: 2.5s;
}

/* アニメーション自体が変化する時間を決めるCSS*/
.change-time05 {
  animation-duration: 0.5s;
}

.change-time1 {
  animation-duration: 1s;
}

.change-time15 {
  animation-duration: 1.5s;
}

.change-time2 {
  animation-duration: 2s;
}

.change-time25 {
  animation-duration: 2.5s;
}

.shake {
  animation: hoverShake 3s ease 1;
}

@keyframes hoverShake {
  0% {
    transform: rotate(0deg) translate(0px, 0px);
  }
  2% {
    transform: rotate(-3deg) translate(-3px, 0px);
  }
  4% {
    transform: rotate(3deg) translate(3px, 0px);
  }
  6% {
    transform: rotate(-6deg) translate(-6px, 0px);
  }
  8% {
    -webkit-transform: rotate(18deg);
    transform: rotate(6deg) translate(6px, 0px);
  }
  10% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-9deg) translate(-9px, 0px);
  }
  12% {
    -webkit-transform: rotate(22deg);
    transform: rotate(9deg) translate(9px, 0px);
  }
  14% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-6deg) translate(-6px, 0px);
  }
  16% {
    -webkit-transform: rotate(18deg);
    transform: rotate(6deg) translate(6px, 0px);
  }
  18% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-3deg) translate(-3px, 0px);
  }
  20% {
    -webkit-transform: rotate(12deg);
    transform: rotate(3deg) translate(3px, 0px);
  }
  22% {
    transform: rotate(0deg) translate(0px, 0px);
  }
}

[data-target] {
  cursor: pointer;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  overflow-wrap: break-word;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  letter-spacing: 0.07em;
  background-size: contain;
  background-repeat: repeat;
  background-position: center;
  visibility: hidden;
  opacity: 0;
  transition: all 1s;
  font-feature-settings: "palt";
  overflow-x: hidden;
}

body[data-loading="true"] {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 767px) {
  body {
    font-size: 1.4rem;
    min-width: unset;
  }
}

body.is-checked {
  overflow: hidden;
}

ul,
li {
  list-style: none;
}

article,
aside,
footer,
header,
nav,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
ul,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: top;
}

video {
  max-width: 100%;
  height: auto;
}

code,
kbd,
pre,
samp {
  font-size: inherit;
}

ul,
ol {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
}

th,
td {
  padding: 4px 6px;
  text-align: left;
  vertical-align: top;
}

blockquote,
q {
  quotes: none;
}

blockquote::after,
blockquote::before,
q::after,
q::before {
  content: "";
  content: none;
}

address {
  font-style: normal;
}

form {
  margin: 0;
  padding: 0;
}

form button {
  width: auto;
  display: inline-block;
  padding: 0.6em 2em;
  margin: 0;
  background-image: none;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 0;
  font-size: 1.6rem;
  cursor: pointer;
  -webkit-appearance: none;
  transition: all 0.3s ease 0s;
}

form button:hover, form button:focus {
  outline: none;
}

form button:hover {
  opacity: 0.7;
}

form button ::-moz-focus-inner {
  padding: 0;
  border: none;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  border: none;
  background-image: none;
  font-family: inherit;
  font-size: 1.6rem;
  -webkit-appearance: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  border: 0.5px solid #c3c4c6;
}

@media (max-width: 767px) {
  input,
  textarea {
    width: 100%;
  }
}

textarea:hover, textarea:focus {
  outline: none;
}

textarea:focus {
  outline: none;
  box-shadow: none;
}

textarea ::-moz-focus-inner {
  padding: 0;
  border: none;
}

input:focus {
  outline: none;
  box-shadow: none;
}

input[type="submit"], input[type="button"] {
  width: auto;
  display: block;
  padding: 0;
  margin: 0;
  background: transparent;
  background-image: none;
  text-decoration: none;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  cursor: pointer;
  -webkit-appearance: none;
  transition: all 0.2s ease 0s;
  border-radius: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background: #f6921e;
  font-weight: bold;
  font-size: 1.8rem;
}

input[type="submit"]:hover, input[type="submit"]:focus, input[type="button"]:hover, input[type="button"]:focus {
  opacity: 0.7;
}

input[type="submit"] ::-moz-focus-inner, input[type="button"] ::-moz-focus-inner {
  padding: 0;
  border: none;
}

@media (max-width: 767px) {
  input[type="submit"], input[type="button"] {
    font-size: 1.6rem;
  }
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../asset/font/NotoSansJP-Thin.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../asset/font/Noto_Sans_JP/NotoSansJP-Light.woff") format("woff");
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../asset/font/Noto_Sans_JP/NotoSansJP-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../asset/font/Noto_Sans_JP/NotoSansJP-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../asset/font/Noto_Sans_JP/NotoSansJP-Bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../asset/font/Noto_Sans_JP/NotoSansJP-Black.woff") format("woff");
  font-weight: 900;
}

@font-face {
  font-family: "Roboto";
  src: url("../asset/font/Roboto/Roboto-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("../asset/font/Roboto/Roboto-MediumItalic.ttf") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../asset/font/Roboto/Roboto-Bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url("../asset/font/Roboto/Roboto-BlackItalic.woff2") format("woff2"), url("../asset/font/Roboto/Roboto-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

.inner {
  margin: 0 auto;
  padding: 0;
  max-width: 100%;
  width: 1180px;
  height: inherit;
  position: relative;
}

.inner._big {
  width: 95%;
}

@media (max-width: 767px) {
  .inner._big {
    width: 100%;
  }
}

.inner._middle {
  width: 90%;
}

@media (max-width: 767px) {
  .inner._middle {
    width: 100%;
  }
}

@media (max-width: 1228px) {
  .inner {
    padding-right: 24px;
    padding-left: 24px;
    min-width: unset;
  }
}

@media (max-width: 767px) {
  .inner {
    padding-right: 24px;
    padding-left: 24px;
  }
}

/* .main {
  margin-top: $header-height;
  @include mq("sp") {
    margin-top: $header-height_sp;
  }
} */
/* =============================================================================


     btn


============================================================================= */
.form-btn {
  max-width: 600px;
  border-radius: 75px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 20px;
  transition: 0.2s;
  background: #faaf3b;
  border: 2px solid #faaf3b;
  margin: 24px auto 0;
}

.form-btn._recruit {
  border: 2px solid #e06e00;
  background: #e06e00;
}

.form-btn._recruit:hover .form-btntxt {
  color: #e06e00;
}

.form-btn:hover {
  background: #fff;
  /*     .img {
      background-color: $cr_form-btn;
      background-image: url(../asset/images/common/form-icon_ac.svg);
    } */
}

.form-btn:hover .form-btntxt {
  color: #faaf3b;
}

@media (max-width: 767px) {
  .form-btn {
    max-width: 400px;
    padding: 14px 10px;
  }
}

.form-btnimg {
  border-radius: 50%;
  background: #fff;
  position: relative;
  width: 102px;
  background-image: url(../asset/images/common/form-icon.svg);
  background-size: 66px auto;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.2s;
  border: 2px solid #faaf3b;
}

.form-btnimg._recruit {
  border: 2px solid #e06e00;
}

.form-btnimg:before {
  content: "";
  display: block;
  padding-top: 100%;
}

@media (max-width: 767px) {
  .form-btnimg {
    width: 64px;
    background-size: 36px auto;
  }
}

.form-btntxt {
  text-align: center;
  color: #fff;
  font-size: 3rem;
  font-weight: bold;
  margin: auto;
  transition: 0.2s;
}

@media (max-width: 767px) {
  .form-btntxt {
    font-size: 2rem;
  }
}

.btn {
  width: 400px;
  height: 80px;
  max-width: 100%;
  position: relative;
  background: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  border: 5px solid #47220d;
  font-size: 2.2rem;
  z-index: 1;
  font-weight: bold;
  margin: auto;
}

@media (max-width: 767px) {
  .btn {
    font-size: 1.6rem;
    border: 3.5px solid #47220d;
    max-width: 310px;
    width: 100%;
    height: 60px;
    margin: auto;
  }
}

.btn.footer-btn {
  font-size: 3rem;
  height: 100px;
  max-width: 485px;
}

@media (max-width: 1228px) {
  .btn.footer-btn {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .btn.footer-btn {
    max-width: 310px;
    height: 60px;
    font-size: 1.6rem;
  }
}

.btn:hover {
  color: #fff;
  background: #47220d;
}

.btn:hover span {
  opacity: 0.5;
}

.btn span {
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75px;
  height: 100%;
  background: url(../asset/images/common/butahana.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.2s;
}

@media (max-width: 767px) {
  .btn span {
    width: 50px;
  }
}

@media (max-width: 767px) {
  .btn._service {
    background: #fff;
    color: #47220d;
    border: 2px solid #fff;
  }
  .btn._service span {
    background: #47220d;
  }
  .btn._service span:after {
    background: #47220d;
  }
}

/* =============================================================================


     common


============================================================================= */
.header-logo {
  transition: 0.2s;
  width: 126px;
  position: absolute;
  top: 32px;
  left: 12px;
}

.header-logo img {
  display: block;
}

@media (max-width: 767px) {
  .header-logo {
    width: 86px;
  }
}

.section-ttlbox {
  padding: 120px 0;
}

@media (max-width: 767px) {
  .section-ttlbox {
    padding-bottom: 60px;
  }
}

.section-ttl {
  font-size: 7.5rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-ttl span {
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-size: 2.8rem;
  font-style: italic;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 4.86px;
  margin-top: 120px;
  position: relative;
}

.section-ttl span:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(../asset/images/common/ttl-deco01.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 60px;
  height: 60px;
  background-position: center;
}

@media (max-width: 767px) {
  .section-ttl {
    font-size: 3rem;
  }
  .section-ttl span {
    font-size: 1.4rem;
    margin-top: 60px;
  }
  .section-ttl span:before {
    width: 35px;
    height: 35px;
  }
}

.drawer {
  z-index: 40;
  z-index: 100;
}

@media (max-width: 767px) {
  .drawer {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 0;
    top: 0;
  }
  .drawer:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }
}

.drawer-close {
  display: none;
  position: fixed;
}

@media (max-width: 767px) {
  .drawer-close {
    z-index: 39;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    transition: all 0.3s ease 0s;
  }
  .drawer-close.is-checked {
    display: block;
    opacity: 0.4;
  }
}

.drawer-icon {
  display: none;
}

@media (max-width: 767px) {
  .drawer-icon {
    position: relative;
    z-index: 41;
    text-align: center;
    cursor: pointer;
    background-color: inherit;
    display: block;
    padding: 0;
    width: 100%;
    height: 100%;
    border: none;
    font-weight: bold;
    width: 34px;
    height: 25px;
    width: 33px;
    height: 21px;
  }
}

.drawer-icon:focus {
  outline: none;
}

.drawer-icon.is-checked + body {
  overflow: hidden;
}

.drawer-bars {
  display: none;
}

@media (max-width: 767px) {
  .drawer-bars {
    display: inline-block;
    width: 34px;
    height: 25px;
    position: relative;
    vertical-align: bottom;
    margin: auto;
    width: 33px;
    height: 21px;
  }
}

.drawer-bars:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 78px;
  height: 78px;
  content: "";
  z-index: -1;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .drawer-bars:before {
    width: 54px;
    height: 54px;
  }
}

.drawer-bar {
  position: absolute;
  right: 0;
  border-radius: 0px;
  display: block;
  background: #47220d;
  width: 100%;
  height: 3px;
  transition: all 0.3s linear 0s;
}

.drawer-bar:nth-of-type(1) {
  top: 0;
}

.drawer-bar:nth-of-type(2) {
  top: 11px;
}

@media (max-width: 767px) {
  .drawer-bar:nth-of-type(2) {
    top: 9px;
  }
}

.drawer-bar:nth-of-type(3) {
  top: 22px;
}

@media (max-width: 767px) {
  .drawer-bar:nth-of-type(3) {
    top: 18px;
  }
}

.is-checked .drawer-bar {
  background: #fff;
}

.is-checked .drawer-bar:nth-of-type(1) {
  top: 12px;
  transform: rotate(-45deg);
  width: 100%;
}

.is-checked .drawer-bar:nth-of-type(2) {
  background: transparent;
}

.is-checked .drawer-bar:nth-of-type(3) {
  top: 12px;
  transform: rotate(45deg);
  width: 100%;
}

@media (max-width: 767px) {
  .drawer-content {
    height: 100%;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: auto;
    width: 100%;
    max-width: 90%;
    background: #47220d;
    transition: all 0.5s ease 0s;
    text-align: left;
    margin: auto;
    overflow-y: auto;
    max-height: 100vh;
    opacity: 0;
    visibility: hidden;
  }
  .drawer-content.is-checked {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}

@media (max-width: 1228px) {
  .drawer-content {
    min-width: unset;
  }
}

.drawer-content--right {
  /*   transform: translateX(105%);
  left: auto;
  right: 0; */
}

.drawer-content--right.is-checked {
  transform: 0;
}

@media (max-width: 767px) {
  .drawer-content--right {
    transform: translateX(105%);
    left: auto;
    right: 0;
  }
}

@media (max-width: 767px) {
  .drawer-content--left {
    transform: translateX(-105%);
    left: 0;
    right: auto;
  }
}

@media (max-width: 767px) {
  .drawer-content--op {
    top: 0px;
    bottom: auto;
    height: 100vh;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-width: auto;
    opacity: 0;
    visibility: hidden;
  }
}

@media (max-width: 767px) {
  .drawer-content--top {
    transform: translateY(-105%);
    top: 0px;
    bottom: auto;
    height: 100vh;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-width: auto;
  }
}

.drawer-bg {
  height: 100%;
}

.drawer-bg.is-checked {
  width: 100vw;
  height: calc(100%);
  position: fixed;
  left: 0;
  top: 0px;
  transition: 0.4s;
  background: rgba(0, 0, 0, 0.7);
}

@media (max-width: 767px) {
  .drawer-box {
    padding-top: 100px;
  }
}

.drawer-navbox {
  height: 100%;
}

@media (max-width: 767px) {
  .drawer-navbox:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: contain;
    background: url(../asset/images/top/top_bg02.svg);
    background-repeat: repeat;
    background-size: 432px;
    opacity: 0.5;
  }
}

.g-nav {
  position: relative;
  margin: 0 auto;
  color: #47220d;
  letter-spacing: 5.46px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .g-nav {
    display: block;
    color: #fff;
    padding-top: 80px;
  }
}

.g-nav-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 70px;
}

@media (max-width: 767px) {
  .g-nav-list {
    height: 100%;
    flex-direction: column;
    gap: 20px;
  }
}

.g-nav-item {
  position: relative;
  text-align: justify;
  text-align: center;
}

.g-nav-item.child:hover .g-nav-child {
  opacity: 1;
  visibility: visible;
}

.g-nav-item-link {
  display: inline-block;
  position: relative;
  transition: 0.2s;
  width: 100%;
  font-weight: bold;
  padding: 0em 2em;
  cursor: pointer;
}

@media (max-width: 1228px) {
  .g-nav-item-link {
    padding: 0em 1em;
  }
}

@media (max-width: 767px) {
  .g-nav-item-link {
    font-size: 1.5rem;
  }
}

a.g-nav-item-link:hover {
  opacity: 0.7;
}

.g-nav-child {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 120%;
  padding-top: 10px;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}

@media (max-width: 767px) {
  .g-nav-child {
    width: 100%;
    position: relative;
    transform: none;
    transition: none;
    left: auto;
    visibility: visible;
    opacity: 1;
  }
}

.g-nav-child:before {
  content: "";
  position: absolute;
  bottom: calc(100% - 10px);
  left: 50%;
  transform: translateX(-50%);
  border-style: solid;
  border-bottom: 9px solid #47220d;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 0;
}

@media (max-width: 767px) {
  .g-nav-child:before {
    display: none;
  }
}

.g-nav-child_item + li {
  border-top: 1px solid #fff;
}

@media (max-width: 767px) {
  .g-nav-child_item + li {
    border-top: none;
  }
}

.g-nav-child_item a {
  color: #fff;
  background: #47220d;
  transition: 0.2s;
  display: block;
  width: 100%;
  padding: 0.5em 0;
}

.g-nav-child_item a:hover {
  background: #fff;
  color: #47220d;
}

@media (max-width: 767px) {
  .g-nav-child_item a {
    background: none;
  }
}

.floating {
  right: 32px;
  bottom: 24px;
  width: 132px;
  display: inline-block;
  z-index: 10;
  position: fixed;
  transition: 0.2s;
}

.floating:hover .floating-img02 {
  transform: translateY(10px);
}

@media (max-width: 767px) {
  .floating:hover .floating-img02 {
    transform: translateY(10px);
  }
}

@media (max-width: 767px) {
  .floating {
    right: 16px;
    bottom: 12px;
    width: 66px;
  }
}

.floating-img02 {
  width: 95px;
  transition: 0.5s;
  transform: translateY(33px);
  margin-left: auto;
}

@media (max-width: 767px) {
  .floating-img02 {
    width: 47.5px;
    transform: translateY(17.5px);
  }
}

.floating-img {
  width: 108px;
}

@media (max-width: 767px) {
  .floating-img {
    width: 54px;
  }
}

/* =============================================================================


    inner-page


============================================================================= */
.inner-ttlbox {
  padding-top: 48px;
  position: relative;
  padding-bottom: clamp(60px, 7.321vw, 400px);
}

.inner-ttlbox:before {
  content: "";
  position: absolute;
  top: 50%;
  height: clamp(300px, 43.338vw, 1110px);
  width: clamp(300px, 43.338vw, 1110px);
  top: -14.788vw;
  right: -4.392vw;
  background-image: url(../asset/images/common/inner-logo01.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: -1;
}

@media (max-width: 767px) {
  .inner-ttlbox {
    padding-top: calc(48px + 70px);
  }
}

.inner-ttl {
  display: flex;
  flex-direction: column;
  font-size: clamp(6rem, 5.564vw, 14rem);
  font-weight: 900;
  font-style: italic;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  color: #181d61;
  line-height: 1.15278;
  letter-spacing: 9.12px;
}

.inner-ttl span {
  font-size: clamp(3rem, 2.196vw, 5.8rem);
  font-family: "Noto Sans JP", "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
  color: #47220d;
  font-weight: 900;
  font-style: normal;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .inner-ttl {
    font-size: clamp(4rem, 5.564vw, 14rem);
  }
  .inner-ttl span {
    font-size: clamp(2rem, 2.196vw, 5.8rem);
  }
}

.page {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: bold;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  text-align: center;
}

.page-numbers {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: inherit;
  background: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 2rem;
  height: 40px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 40px;
}

.page-numbers + .page-numbers {
  margin-left: 20px;
}

.page-numbers.current {
  background: #0a0245;
  color: #fff;
  cursor: default;
  opacity: 1;
}

a.page-numbers {
  cursor: pointer;
}

a.page-numbers:hover {
  background: #0a0245;
  color: #fff;
  opacity: 1;
}

.page-numbers.prev,
.page-numbers.next {
  background: none;
  border: none;
  height: 40px;
  position: relative;
  width: 40px;
}

.page-numbers.next:before,
.page-numbers.prev:before {
  background: #0a0245;
  height: 1px;
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 100%;
  content: "";
}

.page-numbers.next:after,
.page-numbers.prev:after {
  background: #0a0245;
  content: "";
  height: 1px;
  position: absolute;
  position: absolute;
  top: calc(50% - 3px);
  right: 0;
  transform: translateY(-50%) rotate(35deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 8px;
}

/* .page-numbers.prev:before,
.page-numbers.next:before {
  border-right: solid 1px #fff;
  border-top: solid 1px #fff;
  content: "";
  height: 10px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-135deg);
          transform: translate(-50%, -50%) rotate(-135deg);
  width: 10px;
}
 */
.page-numbers.prev:hover,
.page-numbers.next:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  background: none;
}

.page-numbers.prev:after {
  transform: translateY(-50%) rotate(-35deg);
  left: 0;
  right: auto;
}

#dialog {
  background: rgba(0, 0, 0, 0.4);
  height: 100vh;
  position: fixed;
  top: 0;
  transition: 0.3s;
  visibility: hidden;
  width: 100vw;
  z-index: 9999999999;
}

#dialog .dialog-box {
  background: #47220d;
  box-shadow: 2px 2px 4px #333;
  left: 50%;
  padding: 30px;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  z-index: 9999999999;
  height: 360px;
  color: #fff;
  max-width: calc(100% - 16px);
}

@media (max-width: 767px) {
  #dialog .dialog-box {
    padding: 16px;
  }
}

.choose_box {
  height: 100%;
  position: relative;
  width: 100%;
  background: #fff;
  border-radius: 15px;
  padding: 40px 0;
}

@media (max-width: 767px) {
  #dialog .dialog-box {
    min-width: 250px;
  }
}

#dialog .dialog-text {
  font-size: 2rem;
  font-weight: bold;
}

@media (max-width: 1014px) {
  #dialog .dialog-text {
    font-size: 1.6rem;
    line-height: 1.6;
  }
}

#dialog #cancel {
  cursor: pointer;
  margin-top: 30px;
  color: #47220d;
  border: 1px solid #fff;
}

#dialog #cancel:hover {
  color: #fff;
}

/* =============================================================================


     footer


============================================================================= */
footer {
  padding: 120px 0 48px;
  position: relative;
  background-image: url(../asset/images/common/info_bg_pc.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

footer:before, footer:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

footer:before {
  background: transparent linear-gradient(0deg, #47220d 0%, #4b2713 10%, #583724 23%, #6d5040 37%, #8b7467 54%, #b1a299 71%, #dfd9d5 89%, #fff 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
}

footer:after {
  opacity: 0.56;
  background-size: contain;
  background: url(../asset/images/top/top_bg02.svg);
  background-repeat: repeat;
  background-size: 284px 285.8551px;
}

.footer-contents {
  position: relative;
  z-index: 2;
}

.footer-name {
  text-align: center;
  color: #fff;
  font-size: 9rem;
  letter-spacing: 16.2px;
  font-weight: 900;
  font-style: italic;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
}

@media (max-width: 767px) {
  .footer-name {
    font-size: 3rem;
  }
}

.footer-btnbox {
  margin-top: 120px;
  display: flex;
  gap: 90px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .footer-btnbox {
    margin-top: 40px;
    flex-direction: column;
    gap: 18px;
  }
}

.footer-btn-item {
  max-width: 50%;
}

@media (max-width: 1228px) {
  .footer-btn-item {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .footer-btn-item {
    width: 100%;
    max-width: 100%;
  }
}

.footer-img {
  max-width: 200px;
  margin: 200px auto 0;
}

@media (max-width: 767px) {
  .footer-img {
    max-width: 100px;
    margin: 40px auto 0;
  }
}

.footer-list {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 9.3px;
}

@media (max-width: 1228px) {
  .footer-list {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 767px) {
  .footer-list {
    margin-top: 40px;
  }
}

.footer-item {
  position: relative;
}

.footer-item.child:hover .footer-child {
  opacity: 1;
  visibility: visible;
}

.footer-item-link {
  color: #fff;
  font-weight: 900;
  transition: 0.2s;
  padding: 0 1.5em;
}

.footer-item-link:hover {
  opacity: 0.7;
}

.footer-copy {
  text-align: center;
  font-weight: 900;
  margin-top: 80px;
  color: #fff;
  letter-spacing: 7.44px;
}

@media (max-width: 767px) {
  .footer-copy {
    margin-top: 40px;
  }
}

.footer-child {
  z-index: 1;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 120%;
  padding-top: 10px;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}

@media (max-width: 767px) {
  .footer-child {
    opacity: 1;
    visibility: visible;
    position: relative;
  }
}

.footer-child:before {
  content: "";
  position: absolute;
  bottom: calc(100% - 10px);
  left: 50%;
  transform: translateX(-50%);
  border-style: solid;
  border-bottom: 9px solid #fff;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 0;
}

@media (max-width: 767px) {
  .footer-child:before {
    display: none;
  }
}

.footer-child_item + li {
  border-top: 1px solid #fff;
}

@media (max-width: 767px) {
  .footer-child_item + li {
    border-top: none;
  }
}

.footer-child_item a {
  color: #47220d;
  background: #fff;
  transition: 0.2s;
  display: block;
  width: 100%;
  padding: 0.5em 0;
}

.footer-child_item a:hover {
  background: #47220d;
  color: #fff;
}

@media (max-width: 767px) {
  .footer-child_item a {
    background: none;
    color: #fff;
    font-weight: bold;
  }
}

/* =============================================================================


     header


============================================================================= */
header {
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0px;
  height: 70px;
  transition: all 0.2s ease-in 0s;
  /*   @include mq("tab") {
    height: 80px;
    width: 100%;
  } */
}

@media (max-width: 1228px) {
  header {
    width: 100%;
  }
}

@media (max-width: 767px) {
  header {
    width: 100%;
    height: 0;
  }
}

.header-contents {
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s;
}

@media (max-width: 1228px) {
  .header-contents {
    flex-direction: row;
  }
}

.header-link {
  transition: 0.2s;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.header-link:hover {
  opacity: 0.7;
}

.header-info {
  font-size: 1.4rem;
  font-weight: 500;
  margin-right: auto;
  margin-left: 60px;
}

@media (max-width: 1228px) {
  .header-info {
    margin-right: 20px;
    margin-left: 20px;
    font-size: 1.2rem;
  }
}

@media (max-width: 767px) {
  .header-info {
    position: absolute;
    padding: 0 24px;
    background: rgba(187, 179, 215, 0.7);
    height: 50px;
    text-align: left;
    margin: 0;
    top: 100%;
    left: 0px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
}

.header-nav-container {
  position: relative;
  z-index: 100;
}

@media (max-width: 767px) {
  .header-nav-container {
    margin-left: 0;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.header-mail {
  width: 147px;
  height: 147px;
  box-shadow: 3px 3px 5px rgba(35, 25, 22, 0.3);
  background: linear-gradient(0deg, #181d61 0%, #00a498 55.76%, #bad8ae 100%);
  transition: 0.2s;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  font-weight: 900;
  font-style: italic;
  color: #fff;
  position: absolute;
  border-radius: 50%;
  top: 40px;
  right: 60px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .header-mail {
    display: none;
  }
}

.header-mail._fix {
  position: fixed;
  right: 16px;
  bottom: 16px;
  visibility: hidden;
  opacity: 0;
  top: auto;
}

@media (max-width: 767px) {
  .header-mail._fix {
    display: flex;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    font-size: 2rem;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 24px;
    visibility: visible;
    opacity: 1;
    border-radius: 0;
  }
}

.header-mail:hover {
  opacity: 0.7;
}

@media (max-width: 1228px) {
  .header-mail {
    width: 107px;
    height: 107px;
    top: 50px;
    font-size: 1.4rem;
  }
}

.header-mail-icon {
  width: 42px;
}

@media (max-width: 1228px) {
  .header-mail-icon {
    width: 30px;
  }
}

@media (max-width: 767px) {
  .header-mail-icon {
    width: 38px;
  }
}

[header-scroll="false"] header._index {
  opacity: 0;
  visibility: hidden;
  background: #fff;
}

@media (max-width: 767px) {
  [header-scroll="false"] header._index {
    background: none;
    opacity: 1;
    visibility: visible;
  }
}

[header-scroll="true"] header._index {
  opacity: 1;
  visibility: visible;
  background: #fff;
}

@media (max-width: 767px) {
  [header-scroll="true"] header._index {
    background: none;
    opacity: 1;
    visibility: visible;
  }
}

[data-scroll="true"] header {
  background-color: rgba(255, 255, 255, 0.6);
}

.pc-dis {
  display: none;
}

@media (max-width: 1366px) {
  .pc-dis {
    display: block;
  }
}

.tab-none {
  display: block;
}

@media (max-width: 1228px) {
  .tab-none {
    display: none;
  }
}

.tab-dis {
  display: none;
}

@media (max-width: 1228px) {
  .tab-dis {
    display: block;
  }
}

.sp-none {
  display: block;
}

@media (max-width: 767px) {
  .sp-none {
    display: none;
  }
}

.sp-dis {
  display: none;
}

@media (max-width: 767px) {
  .sp-dis {
    display: block;
  }
}
